import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../../services/context';
import Resource from './Resource';
import useDiagnosticReportsSearch from '../../hooks/useDiagnosticReportsSearch';
import AppAccordion from '../Layout/AppAccordion';
/**
 * Displays the Coverages results for a specific patient.
 *
 * @param patient
 */
const useStyles = makeStyles((theme) => createStyles({
    notes: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));
function DiagnosticReports({ patient }) {
    var _a;
    const classes = useStyles();
    const { appOrganization } = useContext(AppContext);
    const query = useDiagnosticReportsSearch({
        patient: patient,
        // @ts-ignore
        organization: appOrganization
    });
    return (React.createElement(Resource, { header: "Diagnostic Reports", queryResult: query }, query.data ? (React.createElement(AppAccordion, { header: "Open Labs Orders", className: classes.notes },
        React.createElement(List, null, (_a = query.data.entry) === null || _a === void 0 ? void 0 : _a.map((entry) => {
            var _a;
            const order = entry.resource;
            return (React.createElement(ListItem, { button: true, key: order.id, onClick: () => { } },
                React.createElement(ListItemText, { primary: `${(_a = order.code) === null || _a === void 0 ? void 0 : _a.text} - ${order.effectiveDateTime}` })));
        })))) : undefined));
}
export default DiagnosticReports;
