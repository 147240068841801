/* eslint-disable import/no-cycle */
import { add } from 'date-fns';
import Cookies from 'js-cookie';
import EnvironmentSettings from './EnvironmentSettings';
import { updateAccessToken, validateToken } from './axios';
const authCookieName = `aristamd_auth${EnvironmentSettings.stage ? '_dev' : ''}`;
const authLockCookieName = `aristamd_lock${EnvironmentSettings.stage ? '_dev' : ''}`;
const authCookiePath = '/';
const authCookieDomain = EnvironmentSettings.domainServer;
const authUrl = `https://login${EnvironmentSettings.domainServer}`;
// const idleCookieName = `idle${EnvironmentSettings.stage ? '_dev' : ''}`;
const idleTimeoutInMinutes = 45; // timeout after 45 minutes inactivity
const idleWarningInMinutes = 5; // warn 5 minutes before timeout
const idleTimer = null;
const validateTimer = null;
let sessionStorageLock = false;
export default class Session {
    static getCookie() {
        try {
            let cookie = Cookies.get(authCookieName);
            // A value of '0' indicates user logged out
            if (cookie === '0') {
                return null;
            }
            if (cookie) {
                cookie = JSON.parse(cookie);
            }
            /* if (window.aristamd_auth) {
              if (!cookie) {
                // in case of slow connection, cookie may expire while app is loading
                // here we restore cookie from window variable
                cookie = window.aristamd_auth;
                this.setCookie(cookie);
              }
              // we must delete the window variable now to prevent unintended cookie recovery later
              // in the case of logout, for example
              delete window.aristamd_auth;
            } */
            if (typeof cookie === 'undefined') {
                // Cookie may have expired if validate timer didn't run or was delayed
                // This can happen in IE while browsing for a file and Safari for minimized or inactive tab
                // Attempt to rebuild the cookie from storage
                cookie = this.rebuildCookieFromSessionStorage();
            }
            return cookie;
            // eslint-disable-next-line no-empty
        }
        catch (e) { }
        return null;
    }
    /**
     * Update cookie after refreshing tokens
     * @param data
     */
    static updateCookie(data) {
        const cookie = this.getCookie();
        cookie.access_token = data.access_token;
        cookie.refresh_token = data.refresh_token;
        cookie.expires_in = data.expires_in;
        cookie.received_at = new Date();
        this.setCookie(cookie);
    }
    /**
     * Store auth cookie
     * @param cookie
     */
    static setCookie(cookie) {
        Cookies.set(authCookieName, JSON.stringify(cookie), {
            expires: this.cookieExpires(cookie.expires_in),
            path: authCookiePath,
            domain: authCookieDomain,
            secure: true,
        });
        return cookie;
    }
    /**
     * Redirect to login page on auth service
     */
    static redirectLogin() {
        clearTimeout(idleTimer);
        clearTimeout(validateTimer);
        this.clearSession(true);
        const url = window.location.href;
        window.location.href = `${authUrl}/signin?redirect=${url}`;
    }
    /**
     * Redirect to logout page on auth service which handles logging out of all services
     */
    static logout() {
        this.setLock();
        clearTimeout(idleTimer);
        clearTimeout(validateTimer);
        this.clearSession(true);
        Cookies.remove('scope_organization_id');
        // End Matomo Session
        window.location.href = `${authUrl}/logout?service=specialist_mobile`;
    }
    /**
     * Clear session data
     * @param lock Lock session storage to prevent changes
     */
    static clearSession(lock) {
        if (lock) {
            sessionStorageLock = true;
        }
        this.userId = null;
        sessionStorage.clear();
    }
    /**
     * Do some checks to validate the session, call itself again in 2 seconds
     * @returns {boolean}
     */
    static validate() {
        const cookie = this.getCookie();
        // If the cookie has been deleted, redirect to the login page
        if (!cookie) {
            this.redirectLogin();
            return false;
        }
        // If cookie user is different from the session user, reload the page
        if (cookie.user_id !== Session.userId) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            return false;
        }
        // If cookie expires in 2 minutes or less, refresh the token
        // const elapsedTime = differenceInSeconds(new Date(), cookie.received_at);
        /* if (cookie.expires_in - elapsedTime <= 120 && !this.isLocked()) {
          this.refreshToken();
          return false;
        } */
        this.updateWithNewToken(cookie.access_token);
        this.updateFromCookie(cookie);
        this.keepAlive();
        /* validateTimer = setTimeout(this.validate.bind(this), 2000); */
        return true;
    }
    /**
     * Set a temporary lock cookie to prevent multiple app instances (separate tabs) from trying
     * to refresh token at the same time
     */
    static setLock() {
        const inFifteenSeconds = new Date(new Date().getTime() + (15 * 1000));
        Cookies.set(authLockCookieName, 'true', {
            expires: inFifteenSeconds,
            path: authCookiePath,
            domain: authCookieDomain,
        });
    }
    /**
     * Remove the lock cookie
     */
    static removeLock() {
        Cookies.remove(authLockCookieName, { path: authCookiePath, domain: authCookieDomain });
    }
    /**
     * Returns true if lock cookie has been set
     * @returns boolean
     */
    static isLocked() {
        return !!Cookies.get(authLockCookieName);
    }
    /**
     * Refresh the access token
     */
    /* static refreshToken() {
      this.setLock();
      const cookie = this.getCookie();
  
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${cookie.access_token}`,
      };
  
      const request = {};
      request.client_id = clientId;
      request.client_secret = clientSecret;
      request.grant_type = 'refresh_token';
      request.refresh_token = cookie.refresh_token;
  
      axios({
        method: 'POST',
        headers,
        data: request,
        url: '/oauth/token',
      })
        .then((response) => {
          const { data } = response;
          this.updateWithNewToken(data.access_token);
          this.updateFromCookie(data);
          this.updateCookie(data);
          this.removeLock();
          this.validate();
          const user = selectUser(store.getState());
          if (user.id === null) {
            store.dispatch(fetchUser(Session.userId));
          }
        })
        .catch(() => {
          this.removeLock();
          this.logout();
        });
    } */
    /**
     * Update access token for http requests and assets access
     * @param token
     */
    static updateWithNewToken(token) {
        updateAccessToken(token);
    }
    /**
     * Redirect user to unauthorized page on auth service
     * @param error
     */
    static unauthorized(error) {
        window.location.href = `${authUrl}/unauthorized?service=pes${error ? `&error=${error}` : ''}`;
    }
    /**
     * Initialize session
     * @returns void
     */
    static init() {
        this.clearStaleSession();
        const cookie = this.getCookie();
        if (!cookie) {
            this.redirectLogin();
            return;
        }
        Session.userId = cookie.user_id;
        this.updateWithNewToken(cookie.access_token);
        if (this.validate()) {
            validateToken();
        }
    }
    /**
     * Update auth cookie expiration date to keep it alive, since cookie is destroyed automatically
     * after expiration. When an AristaMD app is opened in a new tab, it will look for auth cookie.
     * If the cookie doesn't exist, the user will be required to log in again. Otherwise, the session
     * is kept alive, and we can initialize a new session from the auth cookie.
     */
    static keepAlive() {
        if (!this.isLocked()) {
            const cookie = this.getCookie();
            this.setCookie(cookie);
        }
    }
    /**
     * Get the access token
     * @returns {*}
     */
    static getToken() {
        const cookie = this.getCookie();
        if (cookie) {
            return cookie.access_token;
        }
        return null;
    }
    /**
     * Set up event listeners, reset last active date, and start monitoring for inactivity
     */
    /* static idleInit() {
      this.addIdleEventListeners();
      this.idleReset();
      this.idleMonitor();
    } */
    /**
     * Add event listeners to reset idle whenever user clicks or presses a key
     */
    static addIdleEventListeners() {
        /* document.addEventListener('mousedown', this.idleReset.bind(this));
        document.addEventListener('keypress', this.idleReset.bind(this)); */
    }
    /**
     * Get the timeout for the user based on their role
     */
    static getIdleTimeoutInMinutes() {
        return idleTimeoutInMinutes;
    }
    /**
     * Get the warning time in minutes
     */
    static getIdleWarningInMinutes() {
        return idleWarningInMinutes;
    }
}
Session.userId = null;
/**
 * Rebuild cookie from session storage oauth data
 */
Session.rebuildCookieFromSessionStorage = () => {
    const oauth = JSON.parse(sessionStorage.getItem('oauth') || 'null');
    if (oauth) {
        const { cookie } = oauth;
        return cookie;
    }
    return null;
};
/**
 * Keep session storage in sync with cookie
 * Tokens in the cookie can change when refresh occurs from another app instance (tab)
 * @param cookie
 */
Session.updateFromCookie = (cookie) => {
    if (sessionStorageLock) {
        return;
    }
    const oauth = JSON.parse(sessionStorage.getItem('oauth') || 'null') || {};
    oauth.access_token = cookie.access_token;
    oauth.refresh_token = cookie.refresh_token;
    oauth.expires_in = cookie.expires_in;
    oauth.user_id = cookie.user_id;
    oauth.received_at = cookie.received_at || new Date();
    sessionStorage.setItem('oauth', JSON.stringify(oauth));
};
/**
 * Returns a new expiration date for the cookie
 * @returns {Date}
 */
Session.cookieExpires = (cookieExpiresInSeconds) => (cookieExpiresInSeconds ? new Date(new Date().getTime() + (cookieExpiresInSeconds * 1000))
    : add(new Date(), { years: 100 }));
/**
 * Clear stale session from previous log in
 * This can happen when a user logs out while using multiple tabs
 */
Session.clearStaleSession = () => {
    const cookieToken = Session.getToken();
    const oauth = JSON.parse(sessionStorage.getItem('oauth') || 'null') || {};
    const sessionToken = oauth.access_token;
    if (sessionToken !== cookieToken) {
        Session.clearSession(false);
    }
};
