import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MUIModal from '@material-ui/core/Modal';
const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
};
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: '90vh',
        overflow: 'auto'
    },
    description: {
        height: '100%'
    }
}));
/**
 * App Modal component.
 *
 * @param children
 * @param isOpen
 * @param handleClose
 */
function Modal({ children, isOpen, handleClose }) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    return (React.createElement(MUIModal, { open: isOpen, onClose: handleClose },
        React.createElement("div", { style: modalStyle, className: classes.paper },
            React.createElement("div", { id: "modal-description", className: classes.description }, children))));
}
export default Modal;
