import React, { useContext } from 'react';
import { AppContext } from '../../services/context';
import Resource from './Resource';
import useCoveragesSearch from '../../hooks/useCoveragesSearch';
/**
 * Displays the Coverages results for a specific patient.
 *
 * @param patient
 */
function Coverages({ patient }) {
    const { appOrganization } = useContext(AppContext);
    const query = useCoveragesSearch({
        patient: patient,
        // @ts-ignore
        organization: appOrganization
    });
    return (React.createElement(Resource, { header: "Coverages", queryResult: query }));
}
export default Coverages;
