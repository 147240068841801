import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PatientTeaser from './PatientTeaser';
import ClinicalNotes from '../FHIR/ClinicalNotes';
import Demographics from '../FHIR/Demographics';
import Coverages from '../FHIR/Coverages';
import DiagnosticReports from '../FHIR/DiagnosticReports';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    result: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
}));
/**
 * Displays the search results for a Patient search.
 * @param bundle
 */
function SearchResults({ bundle }) {
    const classes = useStyles();
    const { entry } = bundle;
    let resourceType;
    // If the Resource type is a OperationOutcome, we need to display the issues
    // instead of the entries
    if (entry) {
        // @ts-ignore
        resourceType = entry[0].resource.resourceType;
    }
    if (!resourceType) {
        resourceType = bundle.resourceType;
    }
    const issues = [];
    // @ts-ignore
    if (entry && resourceType === 'OperationOutcome') {
        entry.forEach((e) => {
            const { resource } = e;
            // @ts-ignore
            issues.push(resource === null || resource === void 0 ? void 0 : resource.issue[0]);
        });
        // @ts-ignore
    }
    else if (bundle.issue && resourceType === 'OperationOutcome') {
        // @ts-ignore
        issues.push(bundle.issue[0]);
    }
    return (React.createElement(Container, { disableGutters: true, className: classes.root },
        React.createElement(Typography, { component: "h2", variant: "h2" }, "Results"),
        entry && resourceType !== 'OperationOutcome' ? entry.map((e) => {
            var _a;
            return (React.createElement(Container, { component: "section", key: (_a = e.resource) === null || _a === void 0 ? void 0 : _a.id, disableGutters: true, className: classes.result },
                React.createElement(PatientTeaser, { entry: e }),
                React.createElement(Demographics, { patient: e }),
                e.resource ? (React.createElement(React.Fragment, null,
                    React.createElement(ClinicalNotes, { patient: e.resource }),
                    React.createElement(Coverages, { patient: e.resource }),
                    React.createElement(DiagnosticReports, { patient: e.resource }))) : null));
        }) : null,
        resourceType === 'OperationOutcome' && issues.length > 0 ? (React.createElement("ul", null, issues.map((i) => { var _a, _b; return React.createElement("li", { key: (_a = i.details) === null || _a === void 0 ? void 0 : _a.text }, (_b = i.details) === null || _b === void 0 ? void 0 : _b.text); }))) : null));
}
export default SearchResults;
