import axios from './axios';
/**
 * Search a FHIR resource. Check https://www.hl7.org/fhir/search.html
 * @param config
 */
export const getResourceSearch = async (config) => {
    var _a;
    const { data } = await axios.post(`/fhir/r4/${config.resource}/_search`, config.requestParams, {
        headers: {
            // The organization header is needed to know which EHR the API is hitting.
            site: (_a = config.organization) === null || _a === void 0 ? void 0 : _a.id
        }
    });
    return data;
};
/**
 * Reads a FHIR resource by FHIR ID.
 * @param config
 */
export const getResourceRead = async (config) => {
    var _a;
    const { data } = await axios.get(`/fhir/r4/${config.resource}/${config.FHIRId}`, {
        headers: {
            // The organization header is needed to know which EHR the API is hitting.
            site: (_a = config.organization) === null || _a === void 0 ? void 0 : _a.id
        }
    });
    return data;
};
