import { useMutation } from 'react-query';
import { FHIRResourceType } from '../services/types';
import { getResourceSearch } from '../services/query';
/**
 * Search a patient using multiple fields. All the fields all optional. Check
 * out https://www.hl7.org/fhir/patient.html#search
 */
export default function usePatientSearch() {
    return useMutation((data) => getResourceSearch({
        ...data,
        resource: FHIRResourceType.Patient,
        requestParams: {
            _id: data.patient.id ? data.patient.id : undefined,
            identifier: data.patient.mrn ? `MRN|${data.patient.mrn}` : undefined,
            family: data.patient.lastName ? data.patient.lastName : undefined,
            given: data.patient.firstName ? data.patient.firstName : undefined,
            birthdate: data.patient.dob ? data.patient.dob : undefined,
            telecom: data.patient.phone ? `phone|${data.patient.phone}` : undefined,
        },
    }));
}
