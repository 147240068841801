import React from 'react';
import ReactJson from 'react-json-view';
import Spinner from '../Layout/Spinner';
import AppAccordion from '../Layout/AppAccordion';
/**
 * Generic component to display a FHIR resource as an accordion. It also handles
 * the error and loading state of the request.
 *
 * @param header The name of the Resource.
 * @param queryResult UseQueryResult object of the query results.
 */
function Resource({ header, queryResult, children }) {
    const { isLoading, error, data } = queryResult;
    if (isLoading) {
        return (React.createElement("div", null,
            "Fetching",
            ' ',
            header,
            React.createElement(Spinner, null)));
    }
    if (error) {
        return (React.createElement("p", null,
            "An error has occurred while fetching",
            ' ',
            header,
            ' ',
            "resource:",
            ' ',
            error.message));
    }
    return (React.createElement(AppAccordion, { header: header }, data
        ? (React.createElement(React.Fragment, null,
            React.createElement(ReactJson, { src: data === null || data === void 0 ? void 0 : data.entry, collapsed: 2 }),
            children))
        : React.createElement("p", null, "The patient has no data for this FHIR resource")));
}
Resource.defaultProps = {
    children: undefined
};
export default Resource;
