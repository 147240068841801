import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '../Layout/Modal';
import useClinicalNotesSearch from '../../hooks/useClinicalNotesSearch';
import { AppContext } from '../../services/context';
import Resource from './Resource';
import AppAccordion from '../Layout/AppAccordion';
import { useBinaryMutation } from '../../hooks/useBinary';
import b64ToBlobUrl from '../../services/b64ToBlobUrl';
const useStyles = makeStyles((theme) => createStyles({
    notes: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    pdfIframe: {
        width: '100%',
        height: '100%',
    }
}));
/**
 * Displays the Clinical Notes for a specific patient.
 *
 * @param patient
 */
function ClinicalNotes({ patient }) {
    var _a;
    const classes = useStyles();
    const { appOrganization } = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const mutation = useBinaryMutation();
    const handleClose = () => {
        setOpen(false);
    };
    const binary = mutation.data;
    let body = (React.createElement("div", null, "No Data"));
    if (binary === null || binary === void 0 ? void 0 : binary.data) {
        body = (React.createElement("iframe", { title: "Blob", src: b64ToBlobUrl(binary.data), className: classes.pdfIframe }));
    }
    const query = useClinicalNotesSearch({
        patient: patient,
        // @ts-ignore
        organization: appOrganization
    });
    const openNote = (noteContent) => {
        var _a;
        const attachmentId = (_a = noteContent[0].attachment.url) === null || _a === void 0 ? void 0 : _a.split('/')[1];
        if (attachmentId && appOrganization) {
            mutation.mutateAsync({
                id: attachmentId,
                organization: appOrganization
            }).then(() => setOpen(true));
        }
    };
    return (React.createElement(Resource, { header: "Clinical Notes", queryResult: query }, query.data ? (React.createElement(AppAccordion, { header: "Open Clinical Notes", className: classes.notes },
        React.createElement(Modal, { isOpen: open, handleClose: handleClose }, body),
        React.createElement(List, null, (_a = query.data.entry) === null || _a === void 0 ? void 0 : _a.map((entry) => {
            var _a;
            const document = entry.resource;
            return (React.createElement(ListItem, { button: true, key: document.id, onClick: () => openNote(document === null || document === void 0 ? void 0 : document.content) },
                React.createElement(ListItemText, { primary: `${(_a = document.type) === null || _a === void 0 ? void 0 : _a.text} - ${document.date}` })));
        })))) : undefined));
}
export default ClinicalNotes;
