import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
    },
}));
/**
 * Header component.
 */
export default function Header() {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(AppBar, { position: "static" },
            React.createElement(Toolbar, null,
                React.createElement(Typography, { variant: "h6", className: classes.title }, "Patient Extraction Service")))));
}
