import { useQuery } from 'react-query';
import axios from '../services/axios';
/**
 * Get all the available organizations to query.
 */
const getOrganizations = async () => {
    const { data } = await axios.get('/available-sites');
    return data;
};
export default function useOrganizations() {
    return useQuery(['organizations'], () => getOrganizations());
}
