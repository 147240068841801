/* eslint-disable import/no-cycle */
import axios from 'axios';
import Session from './Session';
export const configure = ({ baseURL, }) => {
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};
export const updateAccessToken = (token) => {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
};
/**
 * Handles the unauthorized requests in order to clean up the session
 * @returns {Promise}
 */
const handleUnauthorized = () => {
    Session.unauthorized();
    return Promise.resolve();
};
export const validateToken = () => axios.get('/auth-validate').catch(() => handleUnauthorized());
axios.interceptors.response.use((config) => config, (error) => {
    const { response: { status } } = error;
    switch (status) {
        case 401:
            return handleUnauthorized();
        default:
            return Promise.reject(error);
    }
});
export default axios;
