import React, { useContext, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoIcon from '@material-ui/icons/Info';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { AppContext } from '../../services/context';
import DemoPatients from '../Layout/DemoPatients';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    paper: {
        padding: theme.spacing(2)
    },
    config: {
        minHeight: 328
    }
}));
/**
 * Organization filter component. Depending on organization the API will query a
 * diff EHR.
 *
 * @param organizations
 */
function OrganizationFilter({ organizations }) {
    const classes = useStyles();
    const { setOrg, appOrganization } = useContext(AppContext);
    const [organization, setOrganization] = useState('');
    const [config, setConfig] = useState(null);
    const handleChange = (event) => {
        const org = organizations.find((o) => o.id === event.target.value);
        if (org) {
            setConfig(org.configuration);
            setOrg(org);
        }
        setOrganization(event.target.value);
    };
    return (React.createElement(Container, { component: "section", disableGutters: true, className: classes.root },
        React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Card, { className: classes.root },
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { component: "h4", variant: "h5" }, " Select your organization"),
                        React.createElement(FormControl, { className: classes.formControl },
                            React.createElement(InputLabel, { id: "organization-filter-label" }, "Organization"),
                            React.createElement(Select, { labelId: "organization-filter-label", id: "organization-filter", value: organization, onChange: handleChange }, organizations.map((o) => (React.createElement(MenuItem, { key: o.id, value: o.id }, o.name))))))),
                appOrganization ? (React.createElement(Card, null,
                    React.createElement(CardContent, null,
                        React.createElement(DemoPatients, { organization: appOrganization })))) : null),
            React.createElement(Grid, { item: true, xs: 6 }, config
                ? (React.createElement(Card, { className: classes.config },
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { component: "h5", variant: "h6" }, " Configuration"),
                        React.createElement(List, null,
                            React.createElement(ListItem, null,
                                React.createElement(ListItemIcon, null,
                                    React.createElement(InfoIcon, null)),
                                React.createElement(ListItemText, { primary: "EHR", secondary: config.ehr })),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemIcon, null,
                                    React.createElement(InfoIcon, null)),
                                React.createElement(ListItemText, { primary: "Accessing User", secondary: config.apiUsername })),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemIcon, null,
                                    React.createElement(InfoIcon, null)),
                                React.createElement(ListItemText, { primary: "FHIR endpoint", secondary: config.apiEndpoint }))),
                        (appOrganization === null || appOrganization === void 0 ? void 0 : appOrganization.isInDevelopment)
                            ? (React.createElement(Typography, { color: "error" },
                                appOrganization.name,
                                "'s integration is still in development")) : null))) : null))));
}
export default OrganizationFilter;
