import { useQuery } from 'react-query';
import { FHIRResourceType } from '../services/types';
import { getResourceSearch } from '../services/query';
/**
 * Search all the Clinical notes for a patient.
 * @param config
 */
export default function useClinicalNotesSearch(config) {
    return useQuery(['clinicalNotesSearch', config.patient.id], () => {
        var _a, _b;
        return getResourceSearch({
            organization: config.organization,
            resource: FHIRResourceType.ClinicalNote,
            requestParams: {
                patient: (_b = (_a = config.patient.identifier) === null || _a === void 0 ? void 0 : _a.find((i) => { var _a; return ((_a = i.type) === null || _a === void 0 ? void 0 : _a.text) === 'FHIR'; })) === null || _b === void 0 ? void 0 : _b.value,
                category: 'clinical-note'
            }
        });
    });
}
