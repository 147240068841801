import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: theme.spacing(2)
    }
}));
/**
 * Generic spinner for loading
 */
function Spinner() {
    const classes = useStyles();
    return React.createElement(CircularProgress, { className: classes.root });
}
export default Spinner;
