import React from 'react';
import ReactJson from 'react-json-view';
import AppAccordion from '../Layout/AppAccordion';
/**
 * Displays the patient demographics. We should not need to request to the API
 * demographics since it should come as part of the search results query
 *
 * @param patient
 */
function Demographics({ patient }) {
    return (React.createElement(AppAccordion, { header: "Demographics" },
        React.createElement(ReactJson, { src: patient, collapsed: 2 })));
}
export default Demographics;
