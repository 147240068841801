import React from 'react';
import Container from '@material-ui/core/Container';
import Header from './Layout/Header';
import Dashboard from './Layout/Dashboard';
function App() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Container, { component: "main" },
            React.createElement(Dashboard, null))));
}
export default App;
