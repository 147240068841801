import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { createStyles, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => createStyles({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
        display: 'block',
    },
}));
/**
 * Generic accordion to display data
 *
 * @param header
 * @param children
 * @param className
 */
function AppAccordion({ header, children, className }) {
    const classes = useStyles();
    return (React.createElement(Accordion, { className: className },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
            React.createElement(Typography, { className: classes.heading }, header)),
        React.createElement(AccordionDetails, { className: classes.details }, children)));
}
AppAccordion.defaultProps = {
    className: ''
};
export default AppAccordion;
