import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
const useStyles = makeStyles({
    root: {
        minHeight: 110,
    },
});
const patientsEpic = [
    {
        mrn: '202497',
        firstName: 'Allison',
        lastName: 'Mychart',
        dob: new Date(1987, 0, 15),
    },
    {
        mrn: '202499',
        firstName: 'Damon',
        lastName: 'Mychart',
        dob: new Date(1979, 6, 26),
        phone: '608-211-3314'
    }
];
const patientsAthena = [
    {
        mrn: '3199',
        firstName: 'Elsy',
        lastName: 'Pettway',
        dob: new Date(1992, 1, 16),
        phone: ''
    },
    {
        mrn: '1654',
        firstName: 'Raphael',
        lastName: 'Kersh',
        dob: new Date(1991, 0, 30),
        phone: '8188968765'
    }
];
/**
 * Demo patient components. It contains all the patients we know exists in each
 * of the EHR sandboxes.
 *
 * @param organization
 */
function DemoPatients({ organization }) {
    const classes = useStyles();
    let patients;
    switch (organization.configuration.ehr) {
        case 'epic':
        default:
            patients = patientsEpic;
            break;
        case 'athena':
            patients = patientsAthena;
            break;
    }
    return (React.createElement(Container, { className: classes.root },
        React.createElement(Typography, { component: "h3", variant: "h6" },
            "Demo Patients for",
            ' ',
            organization.name),
        React.createElement(Grid, { container: true, spacing: 3 }, patients.map((p) => (React.createElement(Grid, { key: p.mrn, item: true, xs: 6 },
            React.createElement("div", null,
                React.createElement("strong", null, "MRN: "),
                p.mrn),
            React.createElement("div", null,
                React.createElement("strong", null, "First name: "),
                p.firstName),
            React.createElement("div", null,
                React.createElement("strong", null, "Last name: "),
                p.lastName),
            React.createElement("div", null,
                React.createElement("strong", null, "DOB: "),
                format(
                // @ts-ignore
                p.dob, 'yyy-MM-dd')),
            React.createElement("div", null,
                React.createElement("strong", null, "Phone: "),
                p.phone)))))));
}
export default DemoPatients;
