import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isUndefined } from 'lodash';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
/**
 * Patient teaser component for the search results.
 * @param entry
 */
function PatientTeaser({ entry }) {
    var _a, _b;
    // @ts-ignore
    const { resource } = entry;
    const classes = useStyles();
    let name = (_a = resource.name) === null || _a === void 0 ? void 0 : _a.find((n) => n.use === 'official');
    if (isUndefined(name)) {
        name = (_b = resource.name) === null || _b === void 0 ? void 0 : _b.find((n) => n.use === 'usual');
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { component: "h3", variant: "h3", className: classes.root },
            React.createElement("strong", null, "Name:"),
            ' ',
            // @ts-ignore
            name.text),
        React.createElement(Typography, { component: "h4", variant: "h4", className: classes.secondaryHeading },
            React.createElement("strong", null, "ID:"),
            ' ',
            resource.id)));
}
export default PatientTeaser;
