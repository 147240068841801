import { useMutation, useQuery } from 'react-query';
import { getResourceRead } from '../services/query';
import { FHIRResourceType } from '../services/types';
/**
 * Query a single binary with a FHIR ID.
 *
 * @param binaryId
 * @param organization
 */
export default function useBinary(binaryId, organization) {
    return useQuery(['binary', binaryId], () => getResourceRead({
        resource: FHIRResourceType.Binary,
        FHIRId: binaryId,
        organization
    }));
}
export function useBinaryMutation() {
    return useMutation((param) => getResourceRead({
        resource: FHIRResourceType.Binary,
        FHIRId: param.id,
        organization: param.organization
    }));
}
