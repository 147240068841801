import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './components/App';
import Session from './services/Session';
import theme from './services/theme';
import AppProvider from './services/context';
Session.init();
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // We don't need an aggressive re-fetch strategy.
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    }
});
ReactDOM.render(React.createElement(ThemeProvider, { theme: theme },
    React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(CssBaseline, null),
        React.createElement(AppProvider, null,
            React.createElement(App, null)),
        process.env.NODE_ENV === 'development' ? React.createElement(ReactQueryDevtools, null) : null)), document.querySelector('#app'));
