import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import OrganizationFilter from '../Filters/OrganizationFilter';
import PatientFilters from '../Filters/PatientFilters';
import useOrganizations from '../../hooks/useOrganizations';
import { AppContext } from '../../services/context';
import Spinner from './Spinner';
/**
 * Dashboard Component. It displays all the filters for the patient search.
 */
function Dashboard() {
    const { appOrganization } = useContext(AppContext);
    const { data, error, isLoading, } = useOrganizations();
    if (isLoading)
        return React.createElement(Spinner, null);
    if (error) {
        return (React.createElement("p", null,
            "An error has occurred:",
            error.message));
    }
    return (React.createElement(Container, { component: "section", disableGutters: true },
        data && React.createElement(OrganizationFilter, { organizations: data }),
        appOrganization && React.createElement(PatientFilters, null)));
}
export default Dashboard;
