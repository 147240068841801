import * as React from 'react';
import { useState } from 'react';
// Default values for the context
export const defaultValues = {
    appOrganization: null,
    // TS needs to know which functions are available in the context
    setOrg: (org) => { }, // eslint-disable-line @typescript-eslint/no-unused-vars
};
export const AppContext = React.createContext(defaultValues);
/**
 * Handles the current app state
 * @param children
 */
function AppProvider({ children }) {
    const [appOrganization, setAppOrganization] = useState(null);
    const setOrg = (org) => {
        setAppOrganization(org);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AppContext.Provider, { value: {
                appOrganization,
                setOrg,
            } }, children)));
}
export default AppProvider;
