import React, { useContext, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { every } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import usePatientSearch from '../../hooks/usePatientsSearch';
import { AppContext } from '../../services/context';
import SearchResults from '../Layout/SearchResults';
import Spinner from '../Layout/Spinner';
const useStyles = makeStyles((theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(2)
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    progress: {
        margin: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(2)
    }
}));
/**
 * Search filters to find a patient in a EHR.
 */
function PatientFilters() {
    var _a;
    const classes = useStyles();
    const defaultValues = {
        mrn: '',
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
    };
    const { control, handleSubmit, reset, formState } = useForm({ defaultValues });
    const { appOrganization } = useContext(AppContext);
    const mutation = usePatientSearch();
    // Reset the form state when organization changes.
    useEffect(() => {
        mutation.reset();
        reset(defaultValues);
    }, [appOrganization === null || appOrganization === void 0 ? void 0 : appOrganization.id]);
    const onSubmit = (data, event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        mutation.reset();
        const isEmpty = every(data, (d) => d === '');
        if (!isEmpty && appOrganization) {
            mutation.mutate({ patient: data, organization: appOrganization });
        }
    };
    return (React.createElement(Container, { component: "section", disableGutters: true },
        React.createElement(Card, null,
            React.createElement(CardContent, null,
                React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Controller, { render: ({ field }) => (React.createElement(TextField, { ...field, label: "Patient First Name", variant: "outlined", className: classes.textField })), control: control, name: "firstName" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Controller, { render: ({ field }) => (React.createElement(TextField, { ...field, className: classes.textField, label: "Patient Last Name", variant: "outlined" })), control: control, name: "lastName" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Controller, { render: ({ field }) => (React.createElement(TextField, { ...field, variant: "outlined", label: "Patient DOB", type: "date", className: classes.textField, InputLabelProps: {
                                        shrink: true,
                                    } })), control: control, name: "dob" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Controller, { render: ({ field }) => (React.createElement(TextField, { ...field, label: "Patient EHR MRN", variant: "outlined" })), control: control, name: "mrn" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Controller, { render: ({ field }) => (React.createElement(TextField, { ...field, label: "Patient Phone", variant: "outlined" })), control: control, name: "phone" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Button, { variant: "contained", color: "primary", type: "submit", disabled: !formState.isDirty, className: classes.button }, "Extract Patient Data"),
                            React.createElement(Button, { variant: "contained", color: "secondary", onClick: () => { reset(defaultValues); mutation.reset(); }, disabled: !formState.isDirty }, "Clear")))))),
        mutation.data ? React.createElement(SearchResults, { bundle: mutation.data }) : null,
        mutation.error ? React.createElement(SearchResults, { bundle: (_a = mutation.error) === null || _a === void 0 ? void 0 : _a.response.data }) : null,
        mutation.isLoading && React.createElement(Spinner, null)));
}
export default PatientFilters;
