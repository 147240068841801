/**
 * Enum for each FHIR resource we use. The format for each should be:
 * "humanReadableName": "nameInFhir"
 */
export var FHIRResourceType;
(function (FHIRResourceType) {
    FHIRResourceType["Patient"] = "Patient";
    FHIRResourceType["ClinicalNote"] = "DocumentReference";
    FHIRResourceType["Lab"] = "Observation";
    FHIRResourceType["Binary"] = "Binary";
    FHIRResourceType["Coverage"] = "Coverage";
    FHIRResourceType["DiagnosticReport"] = "DiagnosticReport";
})(FHIRResourceType || (FHIRResourceType = {}));
