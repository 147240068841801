import { createMuiTheme } from '@material-ui/core/styles';
// A custom theme for this app
// Check https://material-ui.com/customization/theming/ for more info
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#235a96',
        },
        secondary: {
            main: '#239496',
        },
    },
});
export default theme;
