// Parse the current domain and set the environment settings accordingly.
// For example the following domains would parse as follows:
//   app.aristamd.com -> ["app.aristamd.com", "app", undefined, "aristamd.com"]
//   client1.test.aristamd.com" -> ["client1.test.aristamd.com", "client1", "test", "aristamd.com"]
const url = window.location.host
    .replace('.aristamd.com', '')
    .replace('https://', '')
    .replace('www.', '');
const hostParts = url.split('.').reverse();
const stage = hostParts[0] === 'pes' ? '' : `.${hostParts[0]}`;
const EnvironmentSettings = {
    stage,
    domainServer: `${stage}.aristamd.com`,
};
export default EnvironmentSettings;
